import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import Match from './Match';
import MatchID from './MatchApp/Match-ID';
import MatchChat from './MatchApp/Match-chat';
import MatchSwipe from './MatchApp/Match-swipe';
import Camera from './Camera.js'; // Camera コンポーネントを使用
import Calender from './calender.js'; // Calender コンポーネントを使用
import BankApp from './BankApp.js'; // BankApp コンポーネントを追加
import PhotoApp from './PhotoApp.js'; // PhotoApp コンポーネントをインポート
import { UnderMessageProvider } from './MessageContext.js'; // UnderMessageProviderをインポート
import { responseTable } from './MessageContext.js';  // MessageContext.jsから対応表をインポート
import { response4Camera } from './MessageContext.js';
import { AppProvider } from './MatchApp/AppContext.js';

// 画像を参照
// const backgroundImage = `${process.env.PUBLIC_URL}/images/background.webp`;
const photosIcon = `${process.env.PUBLIC_URL}/images/photos-icon.png`;
const CalenderIcon = `${process.env.PUBLIC_URL}/images/calender-icon.png`;
const cameraIcon = `${process.env.PUBLIC_URL}/images/camera-icon.png`;
const underIcon = `${process.env.PUBLIC_URL}/images/swipe.png`;
const bankIcon = `${process.env.PUBLIC_URL}/images/bank-icon.png`; // BANKアイコンを追加

Amplify.configure(awsExports);

const applications = [
  { name: '写真', id: 'photo', icon: photosIcon },
  { name: 'カレンダー', id: 'calender', icon: CalenderIcon },
  { name: 'カメラ', id: 'camera', icon: cameraIcon },
  { name: 'under', id: 'under', icon: underIcon },
  { name: 'BANK', id: 'bank', icon: bankIcon }, // BANKアプリを追加
];

// 縦向きバッテリーアイコンのSVGコンポーネント
const BatteryIcon = ({ level }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 12 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={`Battery level: ${level}%`}
    >
      {/* バッテリーヘッド部分（上部） */}
      <rect x="4.5" y="1" width="8" height="3" fill="#A9A9A9" />  {/* 薄いグレーのヘッド部分 */}
      
      {/* バッテリーの充電されていない部分（全体背景） */}
      <rect x="1" y="4" width="15" height="18" rx="2" ry="2" fill="#A9A9A9" /> {/* 薄いグレーの背景 */}
      
      {/* バッテリー充電レベル（濃いグレーで下から上に充電） */}
      <rect
        x="1"
        y={`${22 - (16 * level) / 100}`} // レベルに応じて位置を設定（下から充電）
        width="15"
        height={`${(16 * level) / 100}`} // 残量に応じた高さ
        fill="#384043" // 充電されている部分を濃いグレー
      />
    </svg>
  );
};
//Wi-Fiアイコン
const WifiIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="WiFi"
  >
    {/* 最下段の点 */}
    <circle cx="12" cy="18" r="1" fill="#333" />
    
    {/* 下から1番目の曲線 */}
    <path
      d="M9 15c1.5-1.5 4-1.5 5.5 0"
      stroke="#333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    {/* 下から2番目の曲線 */}
    <path
      d="M6 12c3-3 8-3 11 0"
      stroke="#333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    {/* 下から3番目の曲線 */}
    <path
      d="M3 9c5-5 13-5 18 0"
      stroke="#333"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    {/* ダウンロード矢印 */}
    <path
      d="M20 17v-3m0 0l-1.5 1.5m1.5-1.5l1.5 1.5"
      stroke="#333"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    {/* アップロード矢印 */}
    <path
      d="M16 17v3m0 0l-1.5-1.5m1.5 1.5l1.5-1.5"
      stroke="#333"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// FFTのサイズ（波形の棒の数に影響。増やすと波形が詳細に、減らすと粗くなる。）
const FFT_SIZE = 2 ** 13; 


function App({ signOut, user }) {
  const [time, setTime] = useState(new Date());
  const [currentApp, setCurrentApp] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false); // Add useState for isAnimating
  const [battery, setBattery] = useState(92); // バッテリー残量のモックデータ
  const [userText, setUserText] = useState(''); // ユーザー入力の管理
  const [displayText, setDisplayText] = useState(''); // 表示するテキストの管理
  const [isDatePickerOpen, setDatePickerOpen] = useState(false); // 日時選択の管理
  const [isManualTime, setIsManualTime] = useState(false); // 手動設定かどうかのフラグ
  const [userSelectedDate, setUserSelectedDate] = useState(time); // 一時的な日時を保持
  // 日時を "YYYY-MM-DD HH:mm" の形式に変換する関数
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };


  // 日付が選択されたときの処理
  const handleDateChange = () => {
    setTime(userSelectedDate); // 確定された日時を適用
    setIsManualTime(true);

    // フォーマット済みの日時を取得
    const formattedDate = formatDate(userSelectedDate);

    // 対応表に一致する日時がある場合
    if (response4Camera[formattedDate]) {
      const videoURL = response4Camera[formattedDate];
      
      // セッションストレージに保存
      sessionStorage.setItem("specialTIME", videoURL);
      console.log(`specialTIME saved: ${videoURL}`);
      
      // 表示用テキストを更新
      setDisplayText(`特別な動画が設定されました: ${videoURL}`);
    } else {
      // 一致しない場合の表示
      sessionStorage.setItem("specialTIME", "https://d15k62eom0hyt7.cloudfront.net/360video/360_001.mp4");
      console.log(`No match for: ${formattedDate}`); // デバッグ用
      setDisplayText("特別な日時に一致しませんでした。");
    }
  };
  

  // 音声波形
  const [audioStream, setAudioStream] = useState(null);
  const canvasRef = useRef(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    canvas.width = 1000;
    canvas.height = 400;
  
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();
    analyser.fftSize = FFT_SIZE;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
  
    let source;
    if (audioStream) {
      source = audioContext.createMediaStreamSource(audioStream);
      source.connect(analyser);
    }
  
    let phase = 0;
    let blendFactor = audioStream ? 1 : 0; // 初期状態で1に設定
  
    const draw = () => {
      requestAnimationFrame(draw);
  
      if (audioStream) {
        // 音声データがある間はblendFactorを1に保つ
        blendFactor = Math.min(blendFactor + 1, 1); // 増加速度も少し速めに
        analyser.getByteFrequencyData(dataArray);
      } else {
        // 音声データがない場合はblendFactorを速めに減少
        blendFactor = Math.max(blendFactor - 1, 1); // 減少速度を速めに
        for (let i = 0; i < bufferLength; i++) {
          dataArray[i] = 90 + 50 * Math.sin((i / 20) + phase);
        }
        phase += 0.02;
      }
  
      // 実データとダミーデータをblendFactorでブレンド
      const blendedDataArray = dataArray.map((val, i) => {
        const dummyValue = 90 + 50 * Math.sin((i / 20) + phase);
        return dummyValue * (1 - blendFactor) + val * blendFactor;
      });
  
      canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
  
      const barWidth = (canvas.width / bufferLength) * 15;
      let x = 0;
  
      for (let i = 0; i < bufferLength; i++) {
        const barHeight = blendedDataArray[i];
        canvasCtx.fillStyle = `rgb(${27 + barHeight / 6}, ${68 + barHeight / 12}, ${75 + barHeight / 8})`;
        canvasCtx.fillRect(
          x,
          canvas.height - barHeight * (canvas.height / 250),
          barWidth,
          barHeight * (canvas.height / 150)
        );
        x += barWidth + 1;
      }
    };
  
    draw();
  
    return () => {
      audioContext.close();
    };
  }, [audioStream]);
  
  
  // ユーザーがテキストを入力した時の処理
  const handleTextSubmit = (event) => {
    event.preventDefault();
    setDisplayText(userText);

    // 入力が「ヒント」を含む場合、対応する情報をsetDisplayTextに設定
    if (userText.includes('ヒント')) {
      if (responseTable[userText]) {
        setDisplayText(responseTable[userText]);
      } else {
        setDisplayText("ヒントに対応する情報が見つかりません。");
      }
    } else {
      // 通常の処理
      if (responseTable[userText]) {
        setDisplayText(responseTable[userText]);
      } else {
        setDisplayText("私は天の声…行き詰まったときはヒントと打ってみて下さい");
      }
    }

    setUserText(''); // 入力欄をリセット
  };

  // handlePhotoSelectedを定義
  const handlePhotoSelected = (photo) => {
    console.log('Photo selected:', photo); // 選択された写真を確認
   };

  // 時刻の自動更新（1秒ごと）
  useEffect(() => {
    if (!isManualTime) {
      const timer = setInterval(() => {
        setTime(new Date());
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isManualTime]);

  // 手動設定を解除する（必要に応じて）
  const resetToCurrentTime = () => {
    setTime(new Date());
    setIsManualTime(false); // 自動更新に戻す
    sessionStorage.setItem("specialTIME", "https://d15k62eom0hyt7.cloudfront.net/360video/360_001.mp4");
  };

  
  const AppClick = (app) => {
    setIsAnimating(true);
    setTimeout(() => {
      if (app.id === 'under') {
        setCurrentApp('match');
      } else if (app.id === 'camera') {
        setCurrentApp('camera');
      } else if (app.id === 'calender') {
        setCurrentApp('calender');
      } else if (app.id === 'bank') {
        setCurrentApp('bank');
      } else {
        setCurrentApp(app.id);
      }
      setIsAnimating(false);
    }, 300);
  };

  const handleHomeClick = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setCurrentApp(null);
      setIsAnimating(false);
    }, 300);
  };

  return (
    <AppProvider>
    <UnderMessageProvider>
      <div className="App">
        {/* <header className="App-header" style={{ backgroundImage: `url(${backgroundImage})` }}> */}
        <header className="App-header">
        <div className="login-info">
          {user ? (
            <>
              <h3>{user.username}がスマホにログイン中</h3>
              <button onClick={signOut}>ログアウト</button>
            </>
          ) : (
            <h3>権限がありません</h3>
          )}
        </div>

          <div className={`phone-master ${isAnimating ? 'slide-up' : ''}`}>
            <div className="phone-mainframe">
              <canvas ref={canvasRef} className="visualizer" />
            </div>
            <div className="phone">
            <div className="status-bar">
              <span className="time">
                {time.getHours() % 12 === 0 ? 12 : time.getHours() % 12}:{time.getMinutes().toString().padStart(2, '0')}
              </span>
              <div className="right-icons">
              <span className="wifi-icon"><WifiIcon /></span>
                <span className="battery-icon">
                  {battery}% <BatteryIcon level={battery} />
                </span>
              </div>
            </div>
              <div className="crack-overlay"></div>
              <div
                className="clock"
                onDoubleClick={() => setDatePickerOpen(true)} // ダブルクリックを検知
              >
                <h1>{time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h1>
                <p>{time.toLocaleDateString('ja-JP', { month: 'long', day: 'numeric', weekday: 'short' })}</p>
              </div>

              {isDatePickerOpen && (
                <div className="date-picker-overlay">
                  <div className="date-picker-container">
                    <input
                      id="datetime-picker"
                      type="datetime-local"
                      className="date-picker"
                      defaultValue={time.toISOString().slice(0, 16)} // 現在時刻を初期値に
                      onChange={(e) => setUserSelectedDate(new Date(e.target.value))} // 入力値を一時保存
                    />
                    <div className="date-picker-buttons">
                      <button onClick={() => setDatePickerOpen(false)}>閉じる</button>
                      <button
                        onClick={() => {
                          handleDateChange(); // 決定時に日時を確定
                          setDatePickerOpen(false);
                        }}
                        className="confirm-button"
                      >
                        決定
                        </button>
                        {isManualTime && (
                          <button onClick={resetToCurrentTime} className="reset-button">
                            現在時刻に戻す
                          </button>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="applications">
                {applications.map(app => (
                  <div key={app.id} className="app-container" onClick={() => AppClick(app)}>
                    <button className={`app-icon ${app.id}`}>
                      <img src={app.icon} alt={app.name} style={{ width: '100%', height: '100%' }} />
                    </button>
                    <span className="app-name">{app.name}</span>
                  </div>
                ))}
              </div>
              {currentApp === 'match' && <Match setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
              {currentApp === 'match-id' && <MatchID setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
              {currentApp === 'match-swipe' && <MatchSwipe setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
              {currentApp === 'match-chat' && <MatchChat setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />}
              {currentApp === 'camera' && (
              <Camera setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} onAudioStream={setAudioStream} />
              )}
              {currentApp === 'calender' && <Calender setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />} {/* Calender コンポーネントを使用 */}
              {currentApp === 'bank' && <BankApp setCurrentApp={setCurrentApp} handleHomeClick={handleHomeClick} />} {/* BankApp コンポーネントを使用 */}
              {currentApp === 'photo' && (
                  <PhotoApp
                    setCurrentApp={setCurrentApp}
                    handleHomeClick={handleHomeClick}
                    handlePhotoSelected={handlePhotoSelected} // handlePhotoSelectedをPhotoAppに渡す
                  />
                )}
            </div>  
          </div>

          {/* テキストコンソールをphone-masterの外に配置 */}
          {/* <form onSubmit={handleTextSubmit} className="text-console">
            <input
              type="text"
              value={userText}
              onChange={(e) => setUserText(e.target.value)}
              placeholder="メッセージを入力..."
            />
            <button type="submit">送信</button>
          </form>           */}

        </header>
        
          {/* 表示用のテキストエリア */}
          <div className="ghost-text">{displayText}</div>        
        
      </div>
    </UnderMessageProvider>
    </AppProvider>
  );
}

export default withAuthenticator(App);